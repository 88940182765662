import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="role-permission-field"
export default class extends Controller {
  static targets = [
    "template",
    "rolePermissions"
  ];

  addRow(event) {
    event.preventDefault();
    const template = this.templateTarget.content.cloneNode(true);
    this.rolePermissionsTarget.appendChild(template);
  }
}
