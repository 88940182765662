import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "schedulePeriod",
    "alternativeText",
    "modalDialog",
    "duplicateScheduleCheckbox",
    "planPlanTableType",
    "tableHeatPlumb",
    "tableStrait",
    "myTable",
    "template",
    "planPlanDate",
    "planCurrentPeriod",
    "planPastPeriod"
  ];

  connect() {
    this.selectedMasters = new Set();

    this.modal = new bootstrap.Modal(this.element, {})
    this.modal.show()

    this.duplicateScheduleCheckboxTarget.addEventListener("change", this.toggleSchedule.bind(this))
    this.planPlanTableTypeTarget.addEventListener("change", this.toggleTable.bind(this))
  }

  hideBeforeRender(event) {
    if (this.isOpen()) {
      event.preventDefault()
      this.element.addEventListener('hidden.bs.modal', event.detail.resume)
      this.modal.hide()
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }

  toggleSchedule() {
    const isChecked = this.duplicateScheduleCheckboxTarget.checked;

    if (isChecked) {
      this.schedulePeriodTarget.classList.remove("d-none");
      this.alternativeTextTarget.classList.add("d-none");
      this.modalDialogTarget.classList.remove("w-table");
    } else {
      this.schedulePeriodTarget.classList.add("d-none");
      this.alternativeTextTarget.classList.remove("d-none");
      this.modalDialogTarget.classList.add("w-table");
    }
  }

  toggleTable() {
    const selectedValue = this.planPlanTableTypeTarget.value;

    if (selectedValue === 'strait') {
      this.tableHeatPlumbTarget.style.display = 'none';
      this.tableStraitTarget.style.display = '';
    } else {
      this.tableHeatPlumbTarget.style.display = '';
      this.tableStraitTarget.style.display = 'none';
    }
  }

  addRow(event) {
    event.preventDefault();
    const template = this.templateTarget.content.cloneNode(true);

    const uuid = generateUUID();

    template.querySelectorAll("*").forEach((node) => {
      if (node.hasAttributes()) {
        Array.from(node.attributes).forEach((attr) => {
          if (attr.value.includes("{{UUID}}")) {
            attr.value = attr.value.replaceAll("{{UUID}}", uuid);
          }
        });
      }
      if (node.textContent.includes("{{UUID}}")) {
        node.textContent = node.textContent.replaceAll("{{UUID}}", uuid);
      }
    });

    this.myTableTarget.appendChild(template);
  }

  removeRow(event) {
    event.preventDefault();
    const row = event.target.closest("tr");
    if (row) {
      row.remove();
    }
  }

  confirmAction(event) {
    event.preventDefault(); // Зупиняємо стандартну дію кнопки

    const planPlanDate = this.planPlanDateTarget.value;
    const planCurrentPeriod = this.planCurrentPeriodTarget?.value;
    const planPastPeriod = this.planPastPeriodTarget?.value;

    // Вибір дати для створення
    const selectedDate =
      planCurrentPeriod && planPastPeriod
        ? planCurrentPeriod
        : planPlanDate;

    // URL для запиту до контролера
    const url = event.target.dataset.url;

    // Перевіряємо, чи існує план
    fetch(`${url}?q[plan_date]=${selectedDate}`, {
      headers: { Accept: "application/json" },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        if (data.exist) {
          this.showConfirmationModal(data.plan);
        } else {
          this.element.form.submit(); // Продовжуємо стандартну дію, якщо плану немає
        }
      })
      .catch((error) => console.error("Error fetching plan data:", error));
  }

  showConfirmationModal(event) {
    const planDate = this.element.dataset.planDate; // Дата з data-атрибута
    const editUrl = this.element.dataset.editUrl; // Посилання для редагування
    const allowEdit = new Date(planDate) >= new Date(new Date().setDate(1)); // Перевірка можливості редагування

    const confirmationMessage =
      "Вже є створений план на цей місяць. Бажаєте редагувати його чи створити новий?";

    // Створення модального вікна
    const modal = document.createElement("div");
    modal.innerHTML = `
      <div class="modal-overlay">
        <div class="modal">
          <p>${confirmationMessage}</p>
          <div class="modal-actions">
            ${
      allowEdit
        ? `<a href="${editUrl}" class="btn btn-primary">Редагувати</a>`
        : `<button class="btn btn-secondary" disabled>Редагування недоступне</button>`
    }
            <button class="btn btn-danger" id="proceed">Все одно створити новий</button>
          </div>
        </div>
      </div>
    `;
    document.body.appendChild(modal);

    // Додаємо обробник для кнопки "Все одно створити"
    modal.querySelector("#proceed").addEventListener("click", () => {
      modal.remove(); // Видаляємо модалку
      this.element.form.submit(); // Продовжуємо стандартну дію
    });
  }

  toggleMaster(event) {
    const masterId = event.target.value;

    if (event.target.checked) {
      this.selectedMasters.add(masterId); // Додаємо ID до списку
    } else {
      this.selectedMasters.delete(masterId); // Видаляємо ID зі списку
    }
  }

  deleteSelectedMasters(event) {
    event.preventDefault();
    event.stopPropagation();

    console.log("Triggered deleteSelectedMasters");
    console.trace(); // Додати стек викликів для відстеження джерела

    const url = event.target.dataset.url; // Отримуємо маршрут з data-url

    if (!url) {
      console.error("URL для видалення не заданий!");
      return;
    }

    if (this.selectedMasters.size === 0) {
      alert("Оберіть хоча б одного майстра для видалення!");
      return;
    }

    if (confirm("Ви впевнені, що хочете видалити вибраних майстрів?")) {
      fetch(url, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ master_ids: Array.from(this.selectedMasters) }),
      })
        .then(response => {
          alert("Вибраних майстрів успішно видалено!");
          window.location.replace(window.location.href);
        })
        .catch(error => {
          console.error("Помилка сервера:", error);
          alert("Помилка сервера. Спробуйте ще раз.");
        });
    }
  }
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
